/**
 * 面授创建API
 */
import { fetch, post, del, downFile } from 'api/base/axios'

const URL = {
  filterUrl: '/curriculum/authentication/admin/list/',
  delUrl: '/curriculum/authentication/delete/',
  downUrl: '/curriculum/authentication/down_template/',
  uploadUrl: '/curriculum/authentication/import_code/',
  saveCodeUrl: '/curriculum/authentication/save_codes/',
  listCurCodeUrl: '/curriculum/authentication/list_cur_code/',
  delCodeUrl: '/curriculum/authentication/del_code/',
  revokeCodeUrl: '/curriculum/authentication/revoke_code/',
  listUserCodeUrl: '/curriculum/authentication/list_user_code/',
  listAuthCodeUrl: '/curriculum/authentication/list_auth_codes/',
}

/**
 * 查询用户
 * @param data
 * @returns {Promise}
 */
export function listAdminAuth (data) {
  return fetch(URL.filterUrl, data)
}

export function delAuth (uuid) {
  return del(URL.delUrl + uuid + '/')
}

export function downTemplate(){
  return downFile(URL.downUrl)
}

export function uploadCode(params){
  return post(URL.uploadUrl, params)
}

export function saveAuthCode(uuid, params) {
  return post(URL.saveCodeUrl + uuid + '/', params)
}

export function listAuthCode(uuid, params) {
  return fetch(URL.listAuthCodeUrl + uuid + '/', params)
}

export function delAuthCode(id){
  return del(URL.delCodeUrl + id + '/')
}

export function revokeAuthCode(id){
  return post(URL.revokeCodeUrl + id + '/')
}

export function listUserCode(data) {
  return fetch(URL.listUserCodeUrl, data)
}

export function  listCurCode(data) {
  return fetch(URL.listCurCodeUrl, data)
}
