

<template>
  <div>
    <div class="well_frame">
      <h4 class="frame_title">我的授权码</h4>
      <div v-loading="loading">
        <div v-if="pager.total > 0">
          <el-table :data="orderList" class="order_table_common">
            <el-table-column prop="info" label="课程-认证" width="500">
              <template #default="scope">
                <div class="course_item_common pointer">
                  <div class="cover" @click="goPath('/course/detail/' + scope.row.cur_uuid)">
                    <img :src="scope.row.cover_image" />
                    <span class="hot" v-if="scope.row.cover_image_label && scope.row.cover_image_label != 'null'">{{scope.row.cover_image_label}}</span>
                    <span class="tag" v-if="scope.row.type">{{ scope.row.type }}</span>
                  </div>
                  <div class="content">
                    <h4 @click="goPath('/course/detail/' + scope.row.cur_uuid)">{{ scope.row.cur_name }}</h4>
                    <div class="info">
                      <i class="iconfont icon-tubiaocankaoxian1024-05"></i> {{ scope.row.auth_name }}
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="code" label="授权码"></el-table-column>
            <el-table-column prop="expired_at" label="到期日期"></el-table-column>
          </el-table>
          <Pagination :pager="pager" @getPager="getPager"></Pagination>
        </div>
        <!-- 分页 -->
        <el-empty description="暂无授权码" v-else></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
  import Pagination from 'components/common/Pagination'
  import { listUserCode } from "api/course/auth/list"

  export default {
    components: {
      Pagination
    },
    data() {
      return {
        orderList: [],
        pager: {
          currentPage: 1,
          pageSize: 10,
          total: 0,
        },
        loading: true
      }
    },
    mounted() {
      this.listCode()
    },
    methods: {
      listCode() {
        const that = this
        let filter = {
          limit: this.pager.pageSize,
          offset: (this.pager.currentPage - 1) * this.pager.pageSize
        }
        listUserCode(filter).then(res => {
          that.orderList = res.data
          that.pager.total = res.total
          that.loading = false
        })
      },
      stateFormatter(row, column){
        if(row.code) {
          return dayjs(row.expired_at).format('YYYY-MM-DD HH:mm:ss')
        }
        else {
          return '授权码发放中'
        }
      },
      goPath(path) {
        this.$router.push(path)
      },
      getPager(pager) { //获取分页变化后数据
        this.pager = pager
        this.listCode()
      },
    }
  }
</script>
